<template>
  <div class="ex-deposit-auto-crypto">
    <el-form
      :model="form"
      :rules="rules"
      ref="form"
      label-width="100px"
      class="ex-deposit-auto-crypto__form"
    >
      <ex-alert
        v-if="SPECIAL_CURRENCIES_LIST.includes(form.currency)"
        type="warning"
        class="ex-deposit-auto-crypto__message"
      >
        {{WARNING_MESSAGE_CURRENCY_EXPERIMENTAL}}
      </ex-alert>
      <el-form-item label="Asset" prop="currency">
        <el-select
          :loading="isFetching"
          :value="form.currency"
          @change="handleSelectCurrency"
          class="ex-deposit-auto-crypto__select"
        >
          <el-option v-for="(item, index) in currenciesList" :key="index" :value="item" />
        </el-select>
      </el-form-item>
      <el-form-item label="Reference ID" prop="referenceId">
        <el-input v-model.trim="form.referenceId" placeholder="Enter reference id" />
      </el-form-item>
      <el-form-item>
        <el-button
          :disabled="isFetching"
          :loading="isGenerating"
          @click="handleGenerateDepositAddress"
          type="primary"
        >
          Generate
        </el-button>
      </el-form-item>
    </el-form>
    <div class="ex-deposit-auto-crypto__table">
      <ex-deposit-auto-crypto-addresses-list
        :fetch-trigger="triggerFetchAddressesList"
        :currency-list="currenciesList"
        :current-currency="form.currency"
      />
    </div>
  </div>
</template>

<script>
// api
import TunnelApi from '@/api/tunnel.api';

// components
import ExDepositAutoCryptoAddressesList from '@/pages/deposit/crypto/ex-deposit-auto-crypto-addresses-list';
import ExAlert from '@/components/ex-alert';

// utils
import {socketRequest} from '@/utils/socket';
import {ruleMaxLength, ruleRequired} from '@/utils/elementUITypicalValidation';

// const
import {SPECIAL_CURRENCIES_LIST} from '@/constants/currencies';
import {WARNING_MESSAGE_CURRENCY_EXPERIMENTAL} from '@/constants/commonMessage';
import {
  CREATE_DEPOSIT_AUTO_CRYPTO_ADDRESS,
  FETCH_DEPOSIT_AUTO_CRYPTO_CURRENCIES,
} from '@/constants/events/tunnel/actions.type';

export default {
  name: 'ExDepositAutoCrypto',
  components: {ExDepositAutoCryptoAddressesList, ExAlert},
  data() {
    return {
      isGenerating: false,
      triggerFetchAddressesList: false,
      currenciesList: [],
      form: {
        currency: '',
        referenceId: '',
      },
      rules: {
        currency: [ruleRequired],
        referenceId: [ruleRequired, ruleMaxLength(30)],
      },
      isFetching: false,
      WARNING_MESSAGE_CURRENCY_EXPERIMENTAL,
      SPECIAL_CURRENCIES_LIST,
    };
  },
  created() {
    this.fetchCurrencies();
  },
  methods: {
    handleSelectCurrency(value) {
      this.form.currency = value;
      this.$router.replace({name: 'DepositCreate', params: {currency: value}});
    },
    handleGenerateDepositAddress() {
      this.$refs.form.validate((isValid) => {
        if (!isValid) return;
        this.isGenerating = true;
        socketRequest.call(this, CREATE_DEPOSIT_AUTO_CRYPTO_ADDRESS,
          () => {
            this.form.referenceId = '';
            this.$nextTick(() => {
              this.$refs.form.clearValidate('referenceId');
            });
            this.triggerFetchAddressesList = !this.triggerFetchAddressesList;
            return 'New address was created';
          },
          () => {},
          () => {
            this.isGenerating = false;
          },
        );
        TunnelApi.createDepositAutoCryptoAddress(CREATE_DEPOSIT_AUTO_CRYPTO_ADDRESS, this.form);
      });
    },
    fetchCurrencies() {
      socketRequest.call(this, FETCH_DEPOSIT_AUTO_CRYPTO_CURRENCIES,
        (payload) => {
          this.currenciesList = payload;
          if (!_.isArray(this.currenciesList) && this.currenciesList.length <= 0) return;
          this.form.currency = !this.currenciesList.includes(this.$route.params.currency) ? 'BTC' : this.$route.params.currency;
        },
        () => {},
        () => {
          this.isFetching = false;
        },
      );
      this.isFetching = true;
      TunnelApi.fetchDepositAutoCryptoCurrencies(FETCH_DEPOSIT_AUTO_CRYPTO_CURRENCIES);
    },
  },
};
</script>

<style lang="scss" scoped>
.ex-deposit-auto-crypto {
  padding: 20px 0 20px;
  &__form {
    max-width: 490px;
    padding-bottom: 30px;
  }
  &__select {
    width: 100%;
  }
  &__message {
    margin-bottom: 26px;
  }
  ::v-deep {
    .ex-copy {
      color: #67C23A;
      cursor: pointer;
      visibility: hidden;
      font-size: 24px;
      padding: 1px;
      font-weight: 500;
    }
    .el-table__body td {
      &:hover .ex-copy {
        visibility: visible;
      }
    }
  }
}
</style>
