<template>
  <div v-loading="isFetching" class="ex-incoming">
    <el-form
      :model="form"
      :rules="rules"
      class="ex-incoming__form"
      label-width="140px"
      label-position="left"
      ref="form"
    >
      <el-form-item class="ex-incoming__form-item" label="Coin">
        {{currencyFirst}}
      </el-form-item>
      <el-form-item label="Currency">
        {{currencyLast}}
      </el-form-item>
      <el-form-item label="Reference ID" prop="referenceId">
        <el-input v-model.trim="form.referenceId" />
      </el-form-item>
      <el-form-item label="Pair" prop="pair">
        <el-select class="ex-incoming__select" v-model="form.pair">
          <el-option v-for="item in currencyPairs" :key="item.value" :label="item.text" :value="item.value" />
        </el-select>
      </el-form-item>
      <ex-alert
        v-if="SPECIAL_CURRENCIES_LIST.includes(currencyFirst)"
        type="warning"
        class="ex-incoming__message"
      >
        {{WARNING_MESSAGE_CURRENCY_EXPERIMENTAL}}
      </ex-alert>
      <el-button type="primary" :loading="isCreating" @click="createAddress">Generate</el-button>
    </el-form>
    <div class="ex-incoming__table">
      <ex-incoming-addresses-list
        :fetch-trigger="triggerFetchAddressesList"
        :currencies="currencies"
        :receive-options="receiveOptions"
        :convert-options="convertOptions"
      />
    </div>
  </div>
</template>

<script>
// utils
import {ruleMaxLength, ruleRequired} from '@/utils/elementUITypicalValidation';
import {getPairCurrencies} from '@/utils/converters/currency';
import {socketRequest} from '@/utils/socket';

// api
import TunnelApi from '@/api/tunnel.api';

// component
import ExIncomingAddressesList from '@/pages/gateway/ex-incoming-addresses-list';
import ExAlert from '@/components/ex-alert';

// const
import {SPECIAL_CURRENCIES_LIST} from '@/constants/currencies';
import {WARNING_MESSAGE_CURRENCY_EXPERIMENTAL} from '@/constants/commonMessage';
import {FETCH_INCOMING_CURRENCY_PAIRS} from '@/constants/events/tunnel/actions.type';
import {CREATE_INCOMING_ADDRESS} from '@/constants/events/tunnel/actions.type';

export default {
  name: 'ExIncoming',
  components: {ExIncomingAddressesList, ExAlert},
  data() {
    return {
      isCreating: false,
      isFetching: false,
      triggerFetchAddressesList: false,
      form: {
        pair: '',
        referenceId: '',
      },
      currencyFirst: '',
      currencyLast: '',
      rules: {
        pair: [ruleRequired],
        referenceId: [ruleRequired, ruleMaxLength(30)],
      },
      currencyPairs: [],
      receiveOptions: [],
      convertOptions: [],
      WARNING_MESSAGE_CURRENCY_EXPERIMENTAL,
      SPECIAL_CURRENCIES_LIST,
    };
  },
  computed: {
    currencies() {
      return [this.currencyFirst, this.currencyLast];
    },
  },
  watch: {
    ['form.pair'](value) {
      const pair = getPairCurrencies(value);
      this.currencyFirst = pair.first;
      this.currencyLast = pair.second;
    },
  },
  created() {
    this.fetchCurrencyPairs();
  },
  methods: {
    createAddress() {
      this.$refs.form.validate((isValid) => {
        if (isValid) {
          this.isCreating = true;
          socketRequest.call(this, CREATE_INCOMING_ADDRESS,
            () => {
              this.triggerFetchAddressesList = !this.triggerFetchAddressesList;
              return 'New address was created';
            },
            () => {},
            () => {
              this.isCreating = false;
            },
          );
          TunnelApi.createIncomingAddress(CREATE_INCOMING_ADDRESS, {...this.form});
        }
      });
    },
    fetchCurrencyPairs() {
      socketRequest.call(this, FETCH_INCOMING_CURRENCY_PAIRS,
        (payload) => {
          if (!_.isArray(payload) && payload.length <= 0) return;
          this.form.pair = payload[0];
          this.currencyPairs = payload.map((item) => {
            return {value: item, text: item.replace(':', '/')};
          });
          payload.forEach((item) => {
            const pair = getPairCurrencies(item);
            const first = pair.first;
            const second = pair.second;
            if (!this.receiveOptions.find((element) => element.value === first)) {
              this.receiveOptions.push({value: first, text: first});
            }
            if (!this.convertOptions.find((element) => element.value === second)) {
              this.convertOptions.push({value: second, text: second});
            }
          });
        },
        () => {},
        () => {
          this.isFetching = false;
        },
      );
      this.isFetching = true;
      TunnelApi.fetchIncomingCurrencyPairs(FETCH_INCOMING_CURRENCY_PAIRS);
    },
  },
};
</script>

<style lang="scss" scoped>
.ex-incoming {
  padding: 20px 0;
  &__form {
    max-width: 490px;
    padding-bottom: 30px;
    &-item {
      margin-bottom: 0;
    }
  }
  &__select {
    width: 100%;
  }
  &__message {
    margin-bottom: 26px;
  }
}
</style>
